define("labs-zap-search/templates/components/zap-alerts-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RCTbL950",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell zap-alerts-contact\"],[8],[0,\"\\n    \"],[7,\"h5\",true],[8],[0,\"Contact\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"text-small\"],[8],[0,\"Have Suggestions for ZAP Alerts? Contact us at \"],[7,\"a\",true],[10,\"href\",\"mailto:tech@planning.nyc.gov?subject=ZAP Alerts\"],[8],[0,\"tech@planning.nyc.gov\"],[9],[0,\".\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/zap-alerts-contact.hbs"
    }
  });

  _exports.default = _default;
});